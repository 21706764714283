.text-subdued {
  color: #8e8e8e; }

a {
  text-decoration: none; }

html {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased; }

p {
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem; }

ul,
ol {
  margin: 1.5rem 0; }

ul li,
ol li {
  line-height: 1.5rem; }

ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: 0;
  margin-bottom: 0; }

blockquote {
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: .2rem;
  margin-bottom: .1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: .2rem; }

h1 {
  font-size: 4.242rem;
  line-height: 4.5rem;
  margin-top: 3rem; }

h2 {
  font-size: 2.828rem;
  line-height: 3rem;
  margin-top: 3rem; }

h3 {
  font-size: 1.3rem;
  margin-bottom: .75rem; }

h4 {
  font-size: 1rem;
  margin: 0; }

h5 {
  font-size: 0.75rem;
  letter-spacing: .13rem; }

h6 {
  font-size: 0.3535rem; }

/* Tables */
table {
  margin-top: 1.5rem;
  border-spacing: 0px;
  border-collapse: collapse; }

table td,
table th {
  padding: 0;
  line-height: 33px; }

code {
  vertical-align: bottom; }

.lead {
  font-size: 1.414rem; }

.hug {
  margin-top: 0; }

.button-anchor {
  border: none;
  background: none;
  color: #8e8e8e;
  padding: 0; }

@media (max-width: 900px) {
  .desktop-only {
    display: none; } }

.card {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  margin: 0.5rem 0; }
  .card .card__classification {
    width: 3px;
    align-self: stretch;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
    .card .card__classification--drainer, .card .card__classification--higherRisk, .card .card__classification--financiallyStrained {
      background: linear-gradient(#ff564e, #fd9e9a); }
    .card .card__classification--divider {
      background: linear-gradient(#fdc103, #fbfb5f); }
    .card .card__classification--driver, .card .card__classification--lowerRisk, .card .card__classification--financiallyConfidentOrContent {
      background: linear-gradient(#85ce07, #cdf589); }
  .card .card__details {
    padding: 1rem;
    flex: 1; }
    .card .card__details .card__title-block {
      display: flex; }
      .card .card__details .card__title-block h4 {
        margin-bottom: 1rem;
        flex: 1; }
      .card .card__details .card__title-block a {
        display: block;
        user-select: none;
        cursor: pointer;
        font-size: 14px; }
        .card .card__details .card__title-block a img {
          height: 0.8rem;
          margin-right: 0.4rem; }
    .card .card__details p {
      line-height: 1.5rem;
      margin-bottom: 0; }

.icon-button {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .icon-button.small {
    width: 40px;
    height: 40px; }
    .icon-button.small img {
      max-width: 24px;
      max-height: 24px; }
  .icon-button--drainer {
    background: linear-gradient(240deg, #ff564e, #fd9e9a); }
  .icon-button--divider {
    background: linear-gradient(240deg, #fdc103, #fbfb5f); }
  .icon-button--driver {
    background: linear-gradient(240deg, #85ce07, #cdf589); }
  .icon-button img {
    max-width: 32px;
    max-height: 32px; }

.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #424242; }
  .nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%; }
    .nav ul li {
      list-style: none;
      flex: 1;
      align-self: stretch;
      transition: background-color .25s ease-out; }
      .nav ul li:not(:first-of-type) {
        border-left: 1px solid #8e8e8e; }
      .nav ul li.active, .nav ul li.active:hover {
        background: linear-gradient(0deg, #1f1f1f, #313131); }
      .nav ul li:hover {
        background: #313131; }
      .nav ul li a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fafafa;
        text-decoration: none;
        font-size: .75rem;
        text-align: center;
        line-height: 16px; }
        .nav ul li a.active {
          color: #ffffff; }
      .nav ul li .icon-container {
        height: 18px;
        margin-bottom: 3px;
        display: flex;
        align-items: center; }

#resources-icon {
  height: 20px;
  width: 20px; }

.sub-factor {
  margin: 0 1rem; }

.factor {
  padding: 50px 0 calc(60px + 2rem); }
  .factor .factor__definition,
  .factor .factor__classification,
  .factor .factor__challenges {
    padding: 1rem;
    margin: 0 1rem; }
    .factor .factor__definition .subheader,
    .factor .factor__classification .subheader,
    .factor .factor__challenges .subheader {
      font-size: 1rem;
      line-height: 1.4rem;
      color: #8e8e8e;
      letter-spacing: .15rem; }
  .factor .factor__definition .collapsible {
    position: relative; }
    .factor .factor__definition .collapsible.collapsed {
      max-height: 5rem;
      overflow: hidden;
      margin-bottom: 1rem; }
      .factor .factor__definition .collapsible.collapsed .button-read-more {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: .5rem 0 .055rem .5rem;
        background: #ffffff;
        text-transform: uppercase;
        color: #8e8e8e;
        cursor: pointer; }
  .factor .factor__challenges {
    margin-bottom: 1rem; }

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  height: 50px;
  transition: box-shadow .25s ease-in-out;
  text-align: center; }
  @media (max-width: 360px) {
    .toolbar {
      height: 40px; } }
  .toolbar.scrolled {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); }
  .toolbar.with-back-link {
    justify-content: space-between; }
  .toolbar .toolbar__back,
  .toolbar .toolbar__signout {
    position: absolute; }
  .toolbar .toolbar__back {
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 1rem; }
  .toolbar .toolbar__title {
    width: 100%;
    font-weight: 100;
    letter-spacing: .15rem; }
    @media (max-width: 360px) {
      .toolbar .toolbar__title {
        font-size: 80%; } }
  .toolbar .toolbar__signout {
    right: 1rem; }
  .toolbar button {
    justify-self: flex-end; }

.loading-fullscreen {
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.logo-header {
  background: #1f1f1f;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .logo-header img {
    height: 50px; }

#firebaseui_container {
  margin: 3rem 1rem 1rem; }

.email-hint {
  margin: 2rem 1rem 1rem;
  text-align: center; }
  .email-hint__message {
    margin-bottom: .5rem; }
  .email-hint__button {
    background: #fafafa;
    border: none;
    border-radius: 3px;
    padding: .75rem;
    transition: background-color .2s ease-in-out; }

.factor-title {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .factor-title .factor-title-icon {
    margin-right: 1rem;
    flex-shrink: 0; }
  .factor-title h3 {
    margin: 0; }
  .factor-title .priority-icon {
    height: 15px;
    width: 15px;
    margin-left: 1rem; }

.factor-links {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  max-height: 240px;
  flex-shrink: 1;
  padding-bottom: 0.5rem; }

.factor-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-top: 1rem;
  padding: 0 .5rem; }
  .factor-link a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none; }
    .factor-link a:hover, .factor-link a:visited, .factor-link a:active {
      color: #1f1f1f; }
    .factor-link a .icon-button {
      margin: 0 0 0.5rem;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
      position: relative; }
      .factor-link a .icon-button .priority-icon-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        background: #ffffff;
        border-radius: 50%;
        border: 1px solid #8e8e8e; }
      .factor-link a .icon-button .priority-icon {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 9px;
        height: 9px; }
        @media (max-width: 320px) {
          .factor-link a .icon-button .priority-icon {
            width: 9px;
            height: 9px; } }
      .factor-link a .icon-button img[alt="Rest icon"] {
        margin-top: 4px; }
      @media (max-width: 320px) {
        .factor-link a .icon-button {
          width: 40px;
          height: 40px; }
          .factor-link a .icon-button img {
            width: 24px;
            height: 24px; } }
    .factor-link a .factor-link__title {
      font-size: .75rem;
      color: #1f1f1f;
      text-transform: uppercase; }
      @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        .factor-link a .factor-link__title {
          font-size: 0.6rem; } }
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        .factor-link a .factor-link__title {
          font-size: 0.75rem; } }

.factor-score-indicator {
  height: 16px;
  position: relative;
  margin: 1rem 1rem 2rem; }
  .factor-score-indicator .bar,
  .factor-score-indicator .marker {
    position: absolute;
    left: 0;
    background: linear-gradient(to right, #ff564e 0%, #ff564e 20%, #fdc103 50%, #85ce07 75%, #85ce07 100%); }
  .factor-score-indicator .bar {
    top: 4px;
    width: 100%;
    height: 8px;
    border-radius: 3px; }
  .factor-score-indicator .marker {
    top: 0;
    width: 100%;
    height: 16px; }

.article-row {
  display: flex;
  flex-direction: column;
  padding-left: 1rem; }
  .article-row__title-bar {
    display: flex;
    width: 100%;
    margin-bottom: 1rem; }
    .article-row__title-bar .title {
      margin-right: 1rem;
      margin-bottom: 0;
      line-height: .5rem; }
    .article-row__title-bar .horizontal-rule {
      border-bottom: 1px solid #8e8e8e;
      flex: 1; }
  .article-row__content {
    display: flex;
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }

.article-card {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 2rem 0; }
  .article-card:last-child {
    padding-right: 1rem; }
  .article-card__thumbnail, .article-card__caption {
    width: 155px; }
  .article-card__thumbnail {
    height: 120px;
    flex-shrink: 0;
    border: 1px solid #8e8e8e;
    border-radius: 3px;
    background: #ffffff;
    background-repeat: no-repeat;
    background-size: cover; }
  .article-card__caption {
    font-size: .875rem;
    line-height: 1rem;
    margin-top: .5rem;
    color: #1f1f1f; }

.copy-to-clipboard {
  background: #eeeeee;
  border-radius: 3px;
  padding: 0.5rem; }
  .copy-to-clipboard button {
    margin-left: .5rem; }

.content-general {
  margin: 1rem;
  padding: 1rem 1rem 2rem;
  border-bottom: 1px solid #8e8e8e; }
  .content-general .content-general__title {
    margin-bottom: .5rem; }
  .content-general .content-general__description {
    word-wrap: break-word; }
    .content-general .content-general__description ul {
      padding-left: 18px; }
  .content-general .content-general__image {
    width: 100%;
    height: 230px;
    background-size: cover;
    background-repeat: no-repeat; }

.wellbeing-chart {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 285px;
  padding: 2.5rem 0; }
  .wellbeing-chart .chart {
    width: 95px;
    height: 190px;
    position: relative; }
  .wellbeing-chart .chart-overlay {
    position: absolute;
    height: 192px;
    left: -48.5px;
    top: 0; }
  .wellbeing-chart .wedge {
    height: 95px;
    position: absolute;
    transform-origin: bottom left;
    left: 50%; }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    .wellbeing-chart {
      height: 210px; }
      .wellbeing-chart .chart {
        width: 70px;
        height: 140px; }
      .wellbeing-chart .chart-overlay {
        height: 142px;
        left: -36px; }
      .wellbeing-chart .wedge {
        height: 70px; }
      .wellbeing-chart .wellbeing-chart__label {
        font-size: .6rem; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .wellbeing-chart {
      height: 285px; }
      .wellbeing-chart .chart {
        width: 95px;
        height: 190px; }
      .wellbeing-chart .chart-overlay {
        height: 192px;
        left: -48.5px; }
      .wellbeing-chart .wedge {
        height: 95px; }
      .wellbeing-chart .wellbeing-chart__label {
        font-size: .6rem; } }
  .wellbeing-chart .wedge--work {
    transform: rotate(-22.5deg); }
  .wellbeing-chart .wedge--grow {
    transform: rotate(22.5deg); }
  .wellbeing-chart .wedge--stressMastery {
    transform: rotate(67.5deg); }
  .wellbeing-chart .wedge--rest {
    transform: rotate(112.5deg); }
  .wellbeing-chart .wedge--move {
    transform: rotate(157.5deg); }
  .wellbeing-chart .wedge--nourish {
    transform: rotate(202.5deg); }
  .wellbeing-chart .wedge--mindfulAction {
    transform: rotate(247.5deg); }
  .wellbeing-chart .wedge--connect {
    transform: rotate(292.5deg); }
  .wellbeing-chart .wellbeing-chart__labels {
    position: absolute;
    top: 0;
    left: 0; }
    .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label {
      text-transform: uppercase;
      max-width: 3.8rem;
      position: absolute;
      text-align: center;
      color: #1f1f1f;
      letter-spacing: .1rem;
      font-size: 0.75rem; }
      @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label {
          font-size: 0.6rem; } }
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label {
          font-size: 0.75rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--work {
        top: -1.5rem;
        left: 1.7rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--work {
            top: -1.5rem;
            left: 1.1rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--work {
            top: -1.5rem;
            left: 1.7rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--grow {
        top: 0.8rem;
        left: 8rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--grow {
            top: 0.5rem;
            left: 6rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--grow {
            top: 0.8rem;
            left: 8rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--stressMastery {
        top: 5rem;
        left: 9.8rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--stressMastery {
            top: 3.5rem;
            left: 7.2rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--stressMastery {
            top: 5rem;
            left: 9.8rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--rest {
        top: 10.4rem;
        left: 8.2rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--rest {
            top: 8rem;
            left: 6rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--rest {
            top: 10.4rem;
            left: 8.2rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--move {
        top: 12.5rem;
        left: 1.8rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--move {
            top: 9.5rem;
            left: 1.1rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--move {
            top: 12.5rem;
            left: 1.8rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--nourish {
        top: 10.4rem;
        left: -6rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--nourish {
            top: 8rem;
            left: -5.2rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--nourish {
            top: 10.4rem;
            left: -6rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--mindfulAction {
        top: 5rem;
        left: -7.9rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--mindfulAction {
            top: 3.5rem;
            left: -6.5rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--mindfulAction {
            top: 5rem;
            left: -7.9rem; } }
      .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--connect {
        top: 0.8rem;
        left: -6.2rem; }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--connect {
            top: 0.5rem;
            left: -5.2rem; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
          .wellbeing-chart .wellbeing-chart__labels .wellbeing-chart__label--connect {
            top: 0.8rem;
            left: -6.2rem; } }

.wellbeing-info {
  padding: 1rem;
  margin-top: 1rem; }
  .wellbeing-info .wellbeing-info__intro {
    line-height: 1.2rem;
    font-size: 0.875rem; }
    @media (max-width: 320px) {
      .wellbeing-info .wellbeing-info__intro {
        font-size: 80%; } }
  .wellbeing-info .wellbeing-info__list {
    padding: 0 0 0 1.4rem;
    margin: 0; }
  .wellbeing-info .list-item {
    font-size: 0.875rem;
    list-style: none;
    line-height: 1.25rem;
    text-indent: -1.4rem;
    margin-bottom: .75rem; }
    @media (max-width: 320px) {
      .wellbeing-info .list-item {
        font-size: 80%; } }
  .wellbeing-info .classification-dot {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: .5rem;
    vertical-align: text-bottom; }
    @media (max-width: 320px) {
      .wellbeing-info .classification-dot {
        width: 0.75rem;
        height: 0.75rem;
        margin-bottom: 0.1rem; } }
    .wellbeing-info .classification-dot--driver {
      background: #85ce07; }
    .wellbeing-info .classification-dot--divider {
      background: #fdc103; }
    .wellbeing-info .classification-dot--drainer {
      background: #ff564e; }

.tooltip-button {
  position: absolute;
  z-index: 10000;
  left: 1rem;
  width: 42px;
  height: 42px; }
  @media (max-width: 360px) {
    .tooltip-button {
      width: 35px;
      height: 35px; } }
  .tooltip-button .tooltip-button__background,
  .tooltip-button .tooltip-button__icon {
    position: fixed; }
  .tooltip-button .tooltip-button__background {
    top: 0.3rem;
    left: 0.5rem;
    width: 42px;
    height: 42px; }
    @media (max-width: 360px) {
      .tooltip-button .tooltip-button__background {
        width: 35px;
        height: 35px; } }
  .tooltip-button .tooltip-button__icon {
    top: .9rem;
    left: 1.55rem; }
    @media (max-width: 360px) {
      .tooltip-button .tooltip-button__icon {
        top: .8rem;
        left: 1.35rem; } }
    .tooltip-button .tooltip-button__icon.icon--close {
      top: 1.15rem;
      left: 1.45rem; }
      @media (max-width: 360px) {
        .tooltip-button .tooltip-button__icon.icon--close {
          top: 1rem;
          left: 1.12rem; } }

.error-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: calc(50px + 0.5rem); }
  @media screen and (min-width: 64em) {
    .error-modal {
      align-items: center; } }
  .error-modal__background {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.error {
  z-index: 1;
  margin: 1rem;
  padding: 1rem;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-left: 3px solid #ff564e;
  width: 100%;
  overflow: hidden; }

.tab-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem; }

.tab {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .tab:not(:last-child) {
    border-right: 1px solid #8e8e8e; }
  .tab.active a {
    color: #1f1f1f; }
  .tab a {
    padding: 0.25rem 1rem;
    color: #8e8e8e; }

.empty-state {
  margin: 2rem 1rem;
  color: #8e8e8e;
  background: #ffffff;
  border-radius: 3px;
  padding: 1rem;
  text-align: center; }
  .empty-state .empty-state__title {
    text-transform: none;
    letter-spacing: normal; }
  .empty-state .empty-state__message {
    margin-bottom: 0; }

.priorities-list {
  padding: 0.5rem 1rem 1rem;
  background: #8e8e8e;
  font-size: .8rem; }
  .priorities-list__title {
    margin-bottom: 0.5rem;
    line-height: .875rem; }
  .priorities-list__priorities {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    .priorities-list__priorities .priority {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

.panel {
  margin: 1rem;
  padding: 1rem;
  display: flex;
  border-radius: 3px;
  border: 1px solid #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }
  .panel.panel-info {
    border-color: #3c8ff7; }

* {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background: #ffffff;
  color: #1f1f1f; }

.hide {
  display: none; }

.page-profile,
.page-learning-locker,
.page-info {
  padding: 50px 0 60px; }

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.nav-container {
  height: 100%; }

.page-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  overflow-y: scroll; }

.Toastify__toast-body {
  text-align: center; }

pre {
  margin: 0;
  font-size: .875rem;
  line-height: 1rem;
  max-width: 100%;
  overflow: auto; }
